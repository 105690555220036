@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Raleway+Dots&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,700;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		/* font-family: "Great Vibes", cursive; */
		font-family: "Raleway", sans-serif;
		/* font-family: "Raleway Dots", cursive; */
	}
}
