/* src/Cube.css */

.scene {
	width: 200px;
	height: 200px;
	perspective: 600px;
	margin: 100px auto;
	user-select: none; /* Prevent text selection during drag */
}

.cube {
	width: 100%;
	height: 100%;
	position: relative;
	transform-style: preserve-3d;
	transition: transform 0.1s ease-out; /* Smooth out rotation */
}

.cube__face {
	position: absolute;
	width: 200px;
	height: 200px;
	border: 1px solid #ccc;
	background: rgba(255, 255, 255, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	cursor: pointer;
	box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
}

.cube__face--front {
	transform: rotateY(0deg) translateZ(100px);
}
.cube__face--back {
	transform: rotateY(180deg) translateZ(100px);
}
.cube__face--right {
	transform: rotateY(90deg) translateZ(100px);
}
.cube__face--left {
	transform: rotateY(-90deg) translateZ(100px);
}
.cube__face--top {
	transform: rotateX(90deg) translateZ(100px);
}
.cube__face--bottom {
	transform: rotateX(-90deg) translateZ(100px);
}
